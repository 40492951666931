import React from "react";

function Footer() {
  return (
    <div className="bg-footerbg mx-auto items-center">
      <footer className="bg-[url('../public/assets/footerbg.svg')] relative border-3 border-[#407BFF]">
        <img
          src="/assets/Vector 2.svg"
          alt="Vector 2"
          className="absolute top-0 right-0"
        />
        <div className="container mx-auto px-4 pt-16 text-center">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full md:w-1/3 px-10  mb-8 text-left ">
              <span className="text-4xl font-bold ">
                <span className="text-white">Tena</span>
                <span className="text-[#33cecd] font-jakarta">nt</span>
              </span>
              <div className="mt-8 bg-[#c8f7fe] bg-opacity-50 backdrop-blur-md p-4  rounded-[10px] shadow-lg border-2 border-[#b0e0e6]">
                <ul className="list-none p-0 text-left ">
                  <li className="mb-4">
                    <a href="/" className="text-white hover:text-[#33cecd]">
                      BLOGS
                    </a>
                  </li>

                  <li className="mb-4">
                    <a href="/" className="text-white hover:text-[#33cecd]">
                      PRIVACY POLICY
                    </a>
                  </li>
                  <li className="mb-4">
                    <a href="/" className="text-white hover:text-[#33cecd]">
                      TERMS OF SERVICE
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="w-full md:w-1/3 px-4 flex flex-col ">
              <div>
                <div className="flex items-center justify-center space-x-4">
                  <div className="px-6 py-3 bg-white text-[#243063] font-fraunces rounded-[10px] transition duration-300 ease-in-out flex flex-row gap-5 items-center">
                    <div>Couldn't find something?</div>
                    <div className="px-6 py-3 bg-[#243063] text-[white] font-fraunces rounded-[10px] transition duration-300 ease-in-out flex items-center">
                      Mail us
                      <img
                        src="/assets/arrow.svg"
                        alt="Arrow"
                        className="w-6 h-6 ml-2"
                      />
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div className="mt-8 bg-[#c8f7fe] bg-opacity-50 backdrop-blur-md p-4 rounded-[10px] shadow-lg border-2 border-[#b0e0e6]">
                <ul className="list-none p-0 flex flex-row gap-5 justify-between">
                  <li>
                    <a href="/" className="text-white hover:text-[#33cecd]">
                      HOME
                    </a>
                  </li>

                  <li>
                    <a href="/" className="text-white hover:text-[#33cecd]">
                      SERVICES
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-white hover:text-[#33cecd]">
                      PRIVACY POLICY
                    </a>
                  </li>
                  <li>
                    <a href="/" className="text-white hover:text-[#33cecd]">
                      ABOUT
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mt-8 text-white font-fraunces">
                © ALL RIGHTS RESERVED
              </div>
            </div>
            <div className="absolute top-5 right-10 px-4 mb-8">
              <div className="mt-8 flex space-x-4">
                <img
                  src="/assets/fb.svg"
                  alt="Facebook"
                  className="w-10 h-10"
                />
                <img
                  src="/assets/msg.svg"
                  alt="Message"
                  className="w-10 h-10"
                />
                <img src="/assets/x.svg" alt="X" className="w-10 h-10" />
                <img
                  src="/assets/github.svg"
                  alt="Github"
                  className="w-10 h-10"
                />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;