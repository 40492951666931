import React, { useState } from "react";

function Navbar() {
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  return (
    <header className="bg-white ">
      <nav className="flex justify-between items-center max-w-9xl mx-auto p-4">
        <div className="flex items-center">
          <span className="text-2xl font-bold">
            <span className="text-black ">Tena</span>
            <span className="text-[#33cecd] font-jakarta">nt</span>
          </span>
        </div>
        <div className="flex justify-center items-center flex-1 space-x-8">
          <a href="#services" className="text-black hover:text-blue-600">
            Services
          </a>
          <a href="#about-us" className="text-black hover:text-blue-600">
            About Us
          </a>
          <button className="bg-[#003366] text-white text-sm px-2 py-1  rounded-full hover:bg-[#002244]">
            Get started
          </button>
        </div>
        <div
          onClick={handleToggle}
          className={`relative w-12 h-6 bg-gradient-to-b from-[#66FFED] to-[#FFEEB2] rounded-full cursor-pointer transition-all duration-300 ${
            isToggled ? "bg-[#00c8ff]" : ""
          }`}
        >
          <div
            className={`absolute w-5 h-5 bg-yellow-400 rounded-full top-0.5 left-0.5 transition-all duration-300 ${
              isToggled ? "translate-x-6" : ""
            }`}
          ></div>
        </div>
      </nav>
    </header>
  );
}

export default Navbar;