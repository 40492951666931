import React from "react";
import Navbar from "../../components/Navbar";
import MainContent from "./MainContent";
import Footer from "../../components/Footer";

const LandingPage = () => {
  return (
    <div className="LandingPage">
      <Navbar />
      <MainContent />
      <Footer />
    </div>
  );
};

export default LandingPage;