import React from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import { ArrowForward, Star, StarBorder } from "@mui/icons-material";

const MainContent = () => {
  return (<>
    <div className="my-12 text-center px-6">
      {/* Title and header */}
      <header className="mb-12">
        <h1 className="text-5xl text-black font-semibold flex items-center justify-center gap-20 mb-12">
          Connect
          <span className="inline-block w-4 h-4 mx-2 rounded-full bg-custom-gradient"></span>
          <span className="text-[#3784DC] font-medium">Share</span>
          <span className="inline-block w-4 h-4 mx-2 rounded-full bg-custom-gradient"></span>
          Repeat
        </h1>
        <p className="mt-5 text-xl tracking-wide">
          Rent anything, anywhere in the world with us
        </p>
      </header>

      {/* Chat bubbles */}
      <div className="flex flex-row justify-center mx-auto gap-10 mt-16 px-6">
        <div className="bg-gradient-to-b from-[#e5e5e5] to-[#ffffff] p-4 rounded-full rounded-bl-none max-w-md text-xs text-center">
          Hey, do you have camping gear for rent?!
        </div>
        <div>
          <div className="bg-gradient-to-b from-[#e5e5e5] to-[#ffffff] p-4 rounded-full rounded-bl-none max-w-md text-xs text-center -mt-8">
            Hey, do you have camping gear for rent?!
          </div>
        </div>
        <div className="bg-gradient-to-b from-[#e5e5e5] to-[#ffffff] p-4 rounded-full rounded-bl-none max-w-md text-xs text-center">
          Hi! Looking for a 2 bhk apartment rental.
        </div>
      </div>

      {/* Search bar */}
      <div className="relative flex justify-center items-center mt-4 px-6">
        <div className="relative w-full max-w-xs mx-auto">
          <span className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 text-xl z-50">
            <SearchOutlinedIcon className="w-4 h-4 " />
          </span>
          <input
            type="search"
            placeholder="Search properties and more"
            className="w-full py-4 pl-14 border border-gray-300 rounded-full text-base focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-gray-500 drop-shadow-2xl"
          />
        </div>
      </div>

      {/* Three image section */}
      <div className="relative flex justify-center items-center mt-8 mb-32 px-6">
        <div className="relative w-70 h-full p-[4px] bg-custom-gradient rounded-[24px]">
          <div className="rounded-[24px] overflow-hidden bg-white w-full h-full">
            <img
              src="/assets/frame1.png"
              alt="1"
              className="w-79 h-full object-cover"
            />
          </div>
        </div>

        {/* SVG Element */}
        <div className="mx-0 -mb-48">
          <svg
            width="81"
            height="45"
            viewBox="0 0 78 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.931055 44.9501V0.783475C10.9632 10.796 24.4829 16.9324 39.356 16.9324C54.2292 16.9324 67.7489 10.796 77.7811 0.783469V44.9505C67.7489 34.9379 54.2291 28.8014 39.3558 28.8014C24.4828 28.8014 10.9631 34.9377 0.931055 44.9501Z"
              fill="#407BFF"
            />
          </svg>
        </div>

        <div className="relative w-70 h-full p-[4px] bg-custom-gradient -mb-48 rounded-[24px]">
          <div className="rounded-[24px] overflow-hidden bg-white w-full h-full">
            <img
              src="/assets/frame2.png"
              alt="2"
              className="w-79 h-full object-cover"
            />
          </div>
        </div>

        {/* Another SVG Element */}
        <div className="mx-0 -mb-48">
          <svg
            width="81"
            height="45"
            viewBox="0 0 78 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.931055 44.9501V0.783475C10.9632 10.796 24.4829 16.9324 39.356 16.9324C54.2292 16.9324 67.7489 10.796 77.7811 0.783469V44.9505C67.7489 34.9379 54.2291 28.8014 39.3558 28.8014C24.4828 28.8014 10.9631 34.9377 0.931055 44.9501Z"
              fill="#407BFF"
            />
          </svg>
        </div>

        <div className="relative w-70 h-full p-[4px] bg-custom-gradient rounded-[24px]">
          <div className="rounded-[24px] overflow-hidden bg-white w-full h-full">
            <img
              src="/assets/frame3.png"
              alt="3"
              className="w-79 h-full object-cover"
            />
          </div>
        </div>
      </div>

      {/* Our services*/}
<div className="mt-37">
  <div className="relative">
    {/* Original Text */}
    <span className="text-[#c8f7fe] text-4xl font-medium">
      Our Services
    </span>

    {/* Reflected Text with Water Reflection Effect */}
    <span className="text-[#c8f7fe] text-4xl font-medium reflection absolute top-full left-1/2 transform -translate-x-1/2 -translate-y-2">
      Our Services
    </span>
  </div>

  <div className="relative flex justify-center items-center mt-12">
    {/* First Service */}
    <div className="relative w-72 h-full mx-8 rounded-md overflow-hidden">
      <img
        src="/assets/Rectangle 77.png"
        alt="1"
        className="w-full h-full object-cover"
      />
      <button className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-[#c8f7fe] bg-opacity-50 backdrop-blur-md text-white py-2 px-4 rounded-md shadow-lg flex items-center">
        <span>Rent</span>
        <span className="ml-1"> rooms</span>
        <ArrowForward className="ml-1" />
      </button>
    </div>

    {/* Second Service */}
    <div className="relative w-72 h-full mx-8 rounded-md overflow-hidden">
      <img
        src="/assets/Rectangle 77 (1).png"
        alt="1"
        className="w-full h-full object-cover"
      />
      <button className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-[#c8f7fe] bg-opacity-50 backdrop-blur-md text-white py-2 px-4 rounded-md shadow-lg flex items-center">
        <span>Host</span>
        <span className="ml-1"> property</span>
        <ArrowForward className="ml-1" />
      </button>
    </div>

    {/* Third Service */}
    <div className="relative w-72 h-full mx-8 rounded-md overflow-hidden">
      <img
        src="/assets/Rectangle 77 (2).png"
        alt="1"
        className="w-full h-full object-cover"
      />
      <button className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-[#c8f7fe] bg-opacity-50 backdrop-blur-md text-white py-2 px-4 rounded-md shadow-lg flex items-center">
        <span>Find</span>
        <span className="ml-1"> flatmate</span>
        <ArrowForward className="ml-1" />
      </button>
    </div>
  </div>
</div>



      {/* Try Now section */}
      <div className="mt-28 py-1 px-6">
        <div className="container mx-auto px-40 lg:px-50 flex flex-col-reverse lg:flex-row-reverse items-center relative z-10"> {/* Added z-10 to keep content above background */}
    <div className="w-full lg:w-1/2 flex justify-end"> 
      <img src="/assets/rafiki.svg" alt="rafiki SVG" className="max-w-4xl h-[400px] ml-8" /> 
    </div>
    <div className="w-full lg:w-1/2 text-center lg:text-left flex flex-col items-center lg:items-start">
      <h2 className="text-4xl font-thin text-gray-800 mb-24">
        Get your perfect credit score using our AI model
      </h2>

      <button className="px-8 py-4 bg-blue-500 hover:bg-blue-600 text-white rounded-lg text-lg font-bold transition-all">
        Try Now
      </button>
    </div>

        </div>
      </div>

      {/* Location section */}
      <div className="mt-28 px-6">
        <div className="relative">
          <span className="text-[#c8f7fe] text-4xl font-medium">Top Locations</span>
          <span className="text-[#c8f7fe] text-4xl font-semibold reflection absolute top-full left-1/2 transform -translate-x-1/2 -translate-y-2">
            Top Locations
          </span>
        </div>
        <div className="relative flex justify-center items-center mt-12">
          <div className="relative w-full max-w-xs mx-auto">
            <span className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 text-xl z-50">
              <SearchOutlinedIcon className="w-4 h-4 " />
            </span>
            <input
              type="search"
              placeholder="Search properties and more"
              className="w-full py-4 pl-14 border border-gray-300 rounded-full text-base focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-gray-500 drop-shadow-2xl"
            />
          </div>
        </div>
      </div>

      
{/* location list */}
<div className="grid grid-cols-4 sm:grid-cols-6 gap-2 p-3 mt-5 px-6">
  <div className="flex flex-col items-center">
    <div className="w-24 h-24 shadow rounded-lg flex items-center justify-center">
      <img src="/assets/kolkata.png" alt="Kolkata" className="w-16 h-16 rounded-full object-cover" />
    </div>
    <p className="mt-2 text-center">Kolkata</p>
  </div>
  
  <div className="flex flex-col items-center">
    <div className="w-24 h-24 shadow rounded-lg flex items-center justify-center">
      <img src="/assets/mumbai.png" alt="Mumbai" className="w-16 h-16 rounded-full object-cover" />
    </div>
    <p className="mt-2 text-center">Mumbai</p>
  </div>
  
  <div className="flex flex-col items-center">
    <div className="w-24 h-24 shadow rounded-lg flex items-center justify-center">
      <img src="/assets/delhi.png" alt="Delhi" className="w-16 h-16 rounded-full object-cover" />
    </div>
    <p className="mt-2 text-center">Delhi</p>
  </div>
  
  <div className="flex flex-col items-center">
    <div className="w-24 h-24 shadow rounded-lg flex items-center justify-center">
      <img src="/assets/chennai.png" alt="Chennai" className="w-16 h-16 rounded-full object-cover" />
    </div>
    <p className="mt-2 text-center">Chennai</p>
  </div>
  
  <div className="flex flex-col items-center">
    <div className="w-24 h-24 shadow rounded-lg flex items-center justify-center">
      <img src="/assets/noida.png" alt="Noida" className="w-16 h-16 rounded-full object-cover" />
    </div>
    <p className="mt-2 text-center">Noida</p>
  </div>
  
  <div className="flex flex-col items-center">
    <div className="w-24 h-24 shadow rounded-lg flex items-center justify-center">
      <img src="/assets/hyderabad.png" alt="Hyderabad" className="w-16 h-16 rounded-full object-cover" />
    </div>
    <p className="mt-2 text-center">Hyderabad</p>
  </div>
  
  <div className="flex flex-col items-center">
    <div className="w-24 h-24 shadow rounded-lg flex items-center justify-center">
      <img src="/assets/london.png" alt="London" className="w-16 h-16 rounded-full object-cover" />
    </div>
    <p className="mt-2 text-center">London</p>
  </div>
  
  <div className="flex flex-col items-center">
    <div className="w-24 h-24 shadow rounded-lg flex items-center justify-center">
      <img src="/assets/paris.png" alt="Paris" className="w-16 h-16 rounded-full object-cover" />
    </div>
    <p className="mt-2 text-center">Paris</p>
  </div>
  
  <div className="flex flex-col items-center">
    <div className="w-24 h-24 shadow rounded-lg flex items-center justify-center">
      <img src="/assets/sydney.png" alt="Sydney" className="w-16 h-16 rounded-full object-cover" />
    </div>
    <p className="mt-2 text-center">Sydney</p>
  </div>
  
  <div className="flex flex-col items-center">
    <div className="w-24 h-24 shadow rounded-lg flex items-center justify-center">
      <img src="/assets/sanfrancisco.png" alt="San Francisco" className="w-16 h-16 rounded-full object-cover" />
    </div>
    <p className="mt-2 text-center">San Francisco</p>
  </div>
  
  <div className="flex flex-col items-center">
    <div className="w-24 h-24 shadow rounded-lg flex items-center justify-center">
      <img src="/assets/tokyo.png" alt="Tokyo" className="w-16 h-16 rounded-full object-cover" />
    </div>
    <p className="mt-2 text-center">Tokyo</p>
  </div>
  
  <div className="flex flex-col items-center">
    <div className="w-24 h-24 shadow rounded-lg flex items-center justify-center">
      <img src="/assets/tokyo.png" alt="Singapore" className="w-16 h-16 rounded-full object-cover" />
    </div>
    <p className="mt-2 text-center">Singapore</p>
  </div>
</div>


<div class="max-w-5xl mx-auto py-10 mt-10">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-36">
            {/* View component 1 */}
            <div class="p-[4px] hover:bg-custom-gradient rounded-lg hover:scale-110 transition-transform">
              <div class="flex bg-white shadow-lg rounded-lg overflow-hidden">
                <div class="p-4 flex flex-col justify-between">
                  <img
                    src="/assets/dream.png"
                    alt="Dream Abode"
                    class="w-48 h-32 object-cover"
                  />
                  <div class="flex items-center">
                    <Star className="text-yellow-400 ml-1" />
                    <Star className="text-yellow-400 ml-1" />
                    <Star className="text-yellow-400 ml-1" />
                    <Star className="text-yellow-400 ml-1" />
                    <StarBorder className="text-gray-300 ml-1" />
                  </div>
                </div>
                <div class="p-4 flex flex-col justify-between">
                  <div>
                    <h2 class="text-lg font-semibold text-gray-900">
                      Dream Abode Rentals
                    </h2>
                    <p class="text-sm text-gray-500">Kolkata, West Bengal</p>
                    <p class="text-sm text-gray-400 mt-2">
                      Serene retreat for nature lovers, cozy wooden interiors,
                      spacious rooms
                    </p>
                  </div>
                  <div class="flex items-center justify-between mt-4">
                    <div class="text-gray-700 text-sm font-semibold">
                      2500/month
                    </div>
                    <button class="bg-gray-800 text-white text-sm px-5 py-3 rounded-lg focus:outline-none">
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* View component 2 */}
            <div class="p-[4px] hover:bg-custom-gradient rounded-lg hover:scale-110 transition-transform">
              <div class="flex bg-white shadow-lg rounded-lg overflow-hidden">
                <div class="p-4 flex flex-col justify-between">
                  <img
                    src="/assets/dream.png"
                    alt="Dream Abode"
                    class="w-48 h-32 object-cover"
                  />
                  <div class="flex items-center">
                    <Star className="text-yellow-400 ml-1" />
                    <Star className="text-yellow-400 ml-1" />
                    <Star className="text-yellow-400 ml-1" />
                    <Star className="text-yellow-400 ml-1" />
                    <StarBorder className="text-gray-300 ml-1" />
                  </div>
                </div>
                <div class="p-4 flex flex-col justify-between">
                  <div>
                    <h2 class="text-lg font-semibold text-gray-900">
                      Dream Abode Rentals
                    </h2>
                    <p class="text-sm text-gray-500">Kolkata, West Bengal</p>
                    <p class="text-sm text-gray-400 mt-2">
                      Serene retreat for nature lovers, cozy wooden interiors,
                      spacious rooms
                    </p>
                  </div>
                  <div class="flex items-center justify-between mt-4">
                    <div class="text-gray-700 text-sm font-semibold">
                      2500/month
                    </div>
                    <button class="bg-gray-800 text-white text-sm px-5 py-3 rounded-lg focus:outline-none">
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="max-w-5xl mx-auto py-10">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-36">
            {/* View component 1 */}
            <div class="p-[4px] hover:bg-custom-gradient rounded-lg hover:scale-110 transition-transform">
              <div class="flex bg-white shadow-lg rounded-lg overflow-hidden">
                <div class="p-4 flex flex-col justify-between">
                  <img
                    src="/assets/dream.png"
                    alt="Dream Abode"
                    class="w-48 h-32 object-cover"
                  />
                  <div class="flex items-center">
                    <Star className="text-yellow-400 ml-1" />
                    <Star className="text-yellow-400 ml-1" />
                    <Star className="text-yellow-400 ml-1" />
                    <Star className="text-yellow-400 ml-1" />
                    <StarBorder className="text-gray-300 ml-1" />
                  </div>
                </div>
                <div class="p-4 flex flex-col justify-between">
                  <div>
                    <h2 class="text-lg font-semibold text-gray-900">
                      Dream Abode Rentals
                    </h2>
                    <p class="text-sm text-gray-500">Kolkata, West Bengal</p>
                    <p class="text-sm text-gray-400 mt-2">
                      Serene retreat for nature lovers, cozy wooden interiors,
                      spacious rooms
                    </p>
                  </div>
                  <div class="flex items-center justify-between mt-4">
                    <div class="text-gray-700 text-sm font-semibold">
                      2500/month
                    </div>
                    <button class="bg-gray-800 text-white text-sm px-5 py-3 rounded-lg focus:outline-none">
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* View component 2 */}
            <div class="p-[4px] hover:bg-custom-gradient rounded-lg hover:scale-110 transition-transform">
              <div class="flex bg-white shadow-lg rounded-lg overflow-hidden">
                <div class="p-4 flex flex-col justify-between">
                  <img
                    src="/assets/dream.png"
                    alt="Dream Abode"
                    class="w-48 h-32 object-cover"
                  />
                  <div class="flex items-center">
                    <Star className="text-yellow-400 ml-1" />
                    <Star className="text-yellow-400 ml-1" />
                    <Star className="text-yellow-400 ml-1" />
                    <Star className="text-yellow-400 ml-1" />
                    <StarBorder className="text-gray-300 ml-1" />
                  </div>
                </div>
                <div class="p-4 flex flex-col justify-between">
                  <div>
                    <h2 class="text-lg font-semibold text-gray-900">
                      Dream Abode Rentals
                    </h2>
                    <p class="text-sm text-gray-500">Kolkata, West Bengal</p>
                    <p class="text-sm text-gray-400 mt-2">
                      Serene retreat for nature lovers, cozy wooden interiors,
                      spacious rooms
                    </p>
                  </div>
                  <div class="flex items-center justify-between mt-4">
                    <div class="text-gray-700 text-sm font-semibold">
                      2500/month
                    </div>
                    <button class="bg-gray-800 text-white text-sm px-5 py-3 rounded-lg focus:outline-none">
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
{/*Try Now section*/}
<div className="relative mt-4 py-1">
  {/* Full-width background image */}
  <div className="absolute inset-0 z-0">
    <img src="/assets/background.svg" alt="Background" className="w-full h-12xlobject-cover" />
  </div>

  <div className="container mx-auto px-40 lg:px-50 flex flex-col-reverse lg:flex-row-reverse items-center relative z-10"> {/* Added z-10 to keep content above background */}
    <div className="w-full lg:w-1/2 flex justify-end"> 
      <img src="/assets/signrafiki.svg" alt="Signrafiki SVG" className="max-w-4xl h-[400px] ml-8" /> 
    </div>
    <div className="w-full lg:w-1/2 text-center lg:text-left flex flex-col items-center lg:items-start">
      <h2 className="text-4xl font-thin text-gray-800 mb-24">
        Generate your Rental Agreement digitally with u
      </h2>

      <button className="px-6 py-3 bg-blue-600 hover:bg-blue-600 text-white rounded-lg text-lg font-bold transition-all">
        Generate
      </button>
    </div>
  </div>
      </div>


     

    </div>     
    </>
  );
};

export default MainContent;
